import { createApp } from 'vue'
import App from './App.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./assets/scss/main.scss"

import router from './router'
import i18n from './i18n'
const app = createApp(App);
app.use(router);
app.use(i18n);
AOS.init();
app.mount('#app');