<template>
  <header-component/>
  <router-view/>
  <footer-component/>
  <!-- <mobile-menu/> -->
</template>

<script>
import headerComponent from "@/components/header.vue"
import FooterComponent from "@/components/footer.vue"
// import MobileMenu from './components/mobile-menu.vue'

export default {
  name: 'App',
  components: {
    headerComponent,
    FooterComponent
    // MobileMenu,
  }
}
</script>
